import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  tasksList: [],
	links: {},
	meta: {}
};


export const counterSlice = createSlice({
  name: "tasksList",
  initialState,
  reducers: {
		setTasks: (state, action) => {
			state.tasksList = action.payload.data;
			state.links = action.payload.links;
			state.meta = action.payload.meta;
		},
  },
});

export const { setTasks } = counterSlice.actions;

export function getTasks(page, searchTerm) {
  page = page || 1;
  searchTerm = searchTerm || null;

  let endpoint = '/api/tasks?page=' + page;
  if(searchTerm) {
    endpoint = endpoint + '&search=' + searchTerm;
  }

  return async dispatch => {
    const response = await axios.get(endpoint);
    dispatch(counterSlice.actions.setTasks(response.data));
  };
}

export default counterSlice.reducer;
