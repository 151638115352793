import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "@redux-devtools/extension";
import tasksReducer from "./slices/tasks";
import prizesReducer from "./slices/prizes";
import companiesReducer from "./slices/companies";
import usersReducer from "./slices/users";
import submissionsReducer from "./slices/submissions";
import requestsReducer from "./slices/prize-requests";
import notificationsReducer from "./slices/notifications";

export const store = configureStore(
  {
    reducer: {
      users: usersReducer,
      tasks: tasksReducer,
      prizes: prizesReducer,
      companies: companiesReducer,
      submissions: submissionsReducer,
      requests: requestsReducer,
      notifications: notificationsReducer,
    },
  },
  composeWithDevTools()
);
