import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { Drawer as MuiDrawer } from "@mui/material";

import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Sidebar = ({ items, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <SidebarNav items={items} />
    </Drawer>
  );
};

export default Sidebar;
