import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  requestsList: [],
	links: {},
	meta: {}
};


export const requestsSlice = createSlice({
  name: "requestsList",
  initialState,
  reducers: {
		setRequests: (state, action) => {
			state.requestsList = action.payload.data;
			state.links = action.payload.links;
			state.meta = action.payload.meta;
		},
  },
});

export const { setRequests } = requestsSlice.actions;

export function getRequests(page) {
  page = page || 1;
  let endpoint = 'api/prize-requests?page=' + page;

  return async dispatch => {
    const response = await axios.get(endpoint);
    dispatch(requestsSlice.actions.setRequests(response.data));
  };
}

export default requestsSlice.reducer;
