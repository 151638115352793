import { createSlice, createAction } from "@reduxjs/toolkit";

const initialState = {
    notificationsList: [],
}

export const notificationsSlice = createSlice({
    name: "notificationsList",
    initialState,
    reducers: {
        setNotifications: (state, action) => {
            state.notificationsList.push(action.payload);
        },
        removeFirstNotification: (state, action) => {
            state.notificationsList.shift();
        }
    }
});

export function sendNotification(notification) {
    return async dispatch => {
        dispatch(notificationsSlice.actions.setNotifications(notification));
    };
}

export function removeNotification() {
    return async dispatch => {
        dispatch(notificationsSlice.actions.removeFirstNotification());
    };
}

export const { setNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;