import {
  User,
  Users,
  Award,
  Home,
  Layers as Submission,
  Settings
} from "react-feather";

import {
  Apartment as Company,
  FormatListNumbered as Tasks,
} from '@mui/icons-material';

const pages = {
  "Admin": [
    {
      pages: [
        {
          href: "/dashboard/companies",
          title: "Companies",
          icon: Company,
        },
        {
          href: "/dashboard/achievements",
          title: "Achievements",
          icon: Tasks,
        },
        {
          href: "/dashboard/users",
          title: "Users",
          icon: Users,
        },
        {
          href: "/dashboard/prizes",
          title: "Prizes",
          icon: Award,
        },
        {
          href: "/dashboard/submissions",
          title: "Submissions",
          icon: Submission,
        },
        {
          href: "/dashboard/prize-requests",
          title: "Prize Requests",
          icon: Submission,
        },
        {
          href: "/dashboard/settings",
          title: "Settings",
          icon: Settings,
        },
        {
          href: "/profile",
          title: "Profile",
          icon: User,
        },
      ]
    }
  ],
  "Manager": [
    {
      pages: [
        {
          href: "/company/achievements",
          title: "Achievements",
          icon: Tasks,
        },
        {
          href: "/company/users",
          title: "Users",
          icon: Users,
        },
        {
          href: "/company/prizes",
          title: "Prizes",
          icon: Award,
        },
        {
          href: "/profile",
          title: "Profile",
          icon: User,
        },
      ],
    },
  ],
  "Customer": [
    {
      pages: [
        {
          href: "/achievements",
          title: "Achievements",
          icon: Tasks,
        },
        {
          href: "/prizes",
          title: "Prizes",
          icon: Award,
        },
        {
          href: "/profile",
          title: "Profile",
          icon: User,
        },
      ]
    }
  ],
  "Sales": [
    {
      pages: [
        {
          href: "/achievements",
          title: "Achievements",
          icon: Tasks,
        },
        {
          href: "/prizes",
          title: "Prizes",
          icon: Award,
        },
        {
          href: "/profile",
          title: "Profile",
          icon: User,
        },
      ]
    }
  ]
};

const navItems = [
  pages
];

export default navItems;
