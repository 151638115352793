import merge from "deepmerge";
import { green, grey, indigo, red } from "@mui/material/colors";
import { THEMES } from "../constants";

// const customColours = {
//   50: "#e9f0fb",
//   100: "#c8daf4",
//   200: "#a3c1ed",
//   300: "#7ea8e5",
//   400: "#6395e0",
//   500: "#0c9",
//   600: "#407ad6",
//   700: "#f937a0",
//   800: "#2f65cb",
//   900: "#2052c2 ",
// };

const customColours = {
  primary: "#f937a0",
  secondary: "#2b6aff",
  tertiary: "#1e202c",
  success: green[500],
  error: red[500],
  warning: indigo[500],
  info: grey[500],
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customColours.primary,
      contrastText: "#FFF",
    },
    secondary: {
      main: customColours.secondary,
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: customColours.tertiary,
    search: {
      color: grey[800],
    },
    indicator: {
      background: customColours.primary,
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#fff",
    link: {
      color: grey[700],
      hover: {
        color: customColours.primary,
      },
    },
    header: {
      color: grey[200],
      background: customColours.tertiary,
      brand: {
        color: customColours.primary,
      },
    },
    footer: {
      color: grey[200],
      background: "#1E2A38",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customColours["tertiary"],
    },
  },
};

const variants = [defaultVariant];

export default variants;
