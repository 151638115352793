import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  companiesList: [],
	links: {},
	meta: {}
};


export const companiesSlice = createSlice({
  name: "companiesList",
  initialState,
  reducers: {
		setCompanies: (state, action) => {
			state.companiesList = action.payload.data;
			state.links = action.payload.links;
			state.meta = action.payload.meta;
		},
  },
});

export const { setCompanies } = companiesSlice.actions;

export function getCompanies(page, searchTerm) {
  page = page || 1;
  searchTerm = searchTerm || null;

  let endpoint = '/api/companies?page=' + page;
  if(searchTerm) {
    endpoint = endpoint + '&search=' + searchTerm;
  }

  return async dispatch => {
    const response = await axios.get(endpoint);
    dispatch(companiesSlice.actions.setCompanies(response.data));
  };
}

export default companiesSlice.reducer;
