import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import AuthGuard from "../components/guards/AuthGuard";
import { removeNotification } from "../redux/slices/notifications";

import { Box, CssBaseline, Paper as MuiPaper, Snackbar, Alert, Stack, SnackbarContent } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import { Grid } from "@mui/material";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  padding-top: 104px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children, allowedRoles=[] }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  // get notifications list from store
  const dispatch = useDispatch();
  const [snakbar, setSnackbar] = useState(false);
  const notifications = useSelector(state => state.notifications.notificationsList);

  const snakbarHandleClose = (index) => {
    setSnackbar(false);
    dispatch(removeNotification(index));    
  }

  useEffect(() => {
    if(notifications) {
      setSnackbar(true);
    }
  }, [notifications]);

  return (
    <Root>
      <AuthGuard allowedRoles={allowedRoles}>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth, paddingTop: "66px", } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={dashboardItems}
            />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth, paddingTop: "66px", } }}
              items={dashboardItems}
            />
          </Box>
        </Drawer>
        <AppContent>
          <Navbar onDrawerToggle={handleDrawerToggle} />
          <MainContent p={isLgUp ? 12 : 5}>
            {children}
            <Outlet />

            {notifications?.map((notification, key) => (
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={snakbar}
                  onClose={snakbarHandleClose}
                  autoHideDuration={3000}
                  key={`snakbar-notification-${key}`}
                  style={{ top: 80 }}
                >
                  <Alert severity={notification?.type} >{notification.message}</Alert>
                </Snackbar>
            ))}
          </MainContent>          
        </AppContent>
      </AuthGuard>
    </Root>
  );
};

export default Dashboard;
