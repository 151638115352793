import React, {useEffect, useState} from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import {Alert, CssBaseline, Snackbar} from "@mui/material";

import Settings from "../components/Settings";
import GlobalStyle from "../components/GlobalStyle";
import {useDispatch, useSelector} from "react-redux";
import {removeNotification} from "../redux/slices/notifications";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Root = styled.div`
  max-width: 520px;
  margin: -64px auto 0;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const Auth = ({ children }) => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

    // get notifications list from store
    const dispatch = useDispatch();

    const [snakbar, setSnackbar] = useState(false);
    const notifications = useSelector(state => state.notifications.notificationsList);

    const snakbarHandleClose = (index) => {
        setSnackbar(false);
        dispatch(removeNotification(index));
    }

    useEffect(() => {
        if(notifications) {
            setSnackbar(true);
        }
    }, [notifications]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
        {notifications?.map((notification, key) => (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snakbar}
                onClose={snakbarHandleClose}
                autoHideDuration={3000}
                key={`snakbar-notification-${key}`}
            >
                <Alert severity={notification?.type} >{notification.message}</Alert>
            </Snackbar>
        ))}
      <Outlet />
    </Root>
  );
};

export default Auth;
