import React from "react";
import {Navigate} from 'react-router-dom';
import useAuth from "./hooks/useAuth";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard.js";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import VerifyEmail from "./pages/auth/VerifyEmail";

// User
const UserProfile = async(() => import("./pages/user-profile"));

// Dashboard components
// Admin components 
const Default = async(() => import("./pages/admin-dashboards/Default"));
const Users = async(() => import("./pages/admin-dashboards/Users"));
const UsersCreate = async(() => import("./pages/admin-dashboards/Users/Create"));
const UsersUpdate = async(() => import("./pages/admin-dashboards/Users/Update"));
const Companies = async(() => import("./pages/admin-dashboards/Companies"));
const CompaniesUpdate = async(() => import("./pages/admin-dashboards/Companies/Update"));
const CompaniesCreate = async(() => import("./pages/admin-dashboards/Companies/Create"));
const CompanyUsersList = async(() => import("./pages/admin-dashboards/Companies/Users"));
const Tasks = async(() => import("./pages/admin-dashboards/Tasks"));
const TasksCreate = async(() => import("./pages/admin-dashboards/Tasks/Create"));
const TasksUpdate = async(() => import("./pages/admin-dashboards/Tasks/Update"));
const Prizes = async(() => import("./pages/admin-dashboards/Prizes"));
const PrizesCreate = async(() => import("./pages/admin-dashboards/Prizes/Create"));
const PrizesUpdate = async(() => import("./pages/admin-dashboards/Prizes/Update"));
const Submissions = async(() => import("./pages/admin-dashboards/Submissions"));
const SubmissionUpdate = async(() => import("./pages/admin-dashboards/Submissions/Update"));
const PrizeRequests = async(() => import("./pages/admin-dashboards/PrizeRequests"));

// Manager components
const ManagerDefault = async(() => import("./pages/manager-dashboards/Default"));
const ManagerUsers = async(() => import("./pages/manager-dashboards/Users"));
const ManagerUsersCreate = async(() => import("./pages/manager-dashboards/Users/Create"));
const ManagerUsersUpdate = async(() => import("./pages/manager-dashboards/Users/Update"));
const ManagerTasks = async(() => import("./pages/manager-dashboards/Tasks"));

// Customer components
const CustomerTasks = async(() => import("./pages/customer-dashboards/Tasks"));
const CustomerPrizes = async(() => import("./pages/customer-dashboards/Prizes"));

const PrizeRequest = async(() => import("./pages/admin-dashboards/PrizeRequests"));
const PrizeRequestUpdate = async(() => import("./pages/admin-dashboards/PrizeRequests/Update"));

// Configure
const ConfigPage = async(() => import("./pages/admin-dashboards/Config"));

// 404
const NotFound = async(() => import("./pages/auth/Page404"));

const routes = [
  {
    path: "/dashboard",
    element: <DashboardLayout allowedRoles={['Admin']}/>,
    children: [
      {
        path: "settings",
        element: <ConfigPage />,
      },
      {
        path: "companies",
        element: <Companies />,
      },
      {
        path: "companies/create",
        element: <CompaniesCreate />,
      },
      {
        path: "companies/:companyId/update",
        element: <CompaniesUpdate />,
      },
      {
        path: "companies/:companyId/users",
        element: <CompanyUsersList />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "users/create",
        element: <UsersCreate />,
      },
      {
        path: "users/create/company/:companyId",
        element: <UsersCreate />,
      },
      {
        path: "users/:userId/update",
        element: <UsersUpdate />,
      },
      {
        path: "achievements",
        element: <Tasks />,
      },
      {
        path: "achievements/create",
        element: <TasksCreate />,
      },
      {
        path: "achievements/:taskId/update",
        element: <TasksUpdate />,
      },
      {
        path: "prizes",
        element: <Prizes />,
      },
      {
        path: "prizes/create",
        element: <PrizesCreate />,
      },
      {
        path: "prizes/:prizeId/update",
        element: <PrizesUpdate />,
      },
      {
        path: "submissions",
        element: <Submissions />,
      },
      {
        path: "submissions/:submissionId",
        element: <SubmissionUpdate />,
      },
      {
        path: "prize-requests",
        element: <PrizeRequest />
      },
      {
        path: "prize-requests/:prizeRequestId",
        element: <PrizeRequestUpdate />
      }
    ],
  },
  {
    path: "/company",
    element: <DashboardLayout allowedRoles={['Manager']}/>,
    children: [
      {
        path: "users",
        element: <ManagerUsers />,
      },
      {
        path: "users/create",
        element: <ManagerUsersCreate />,
      },
      {
        path: "users/:userId/update",
        element: <ManagerUsersUpdate />,
      },
      {
        path: "achievements",
        element: <ManagerTasks />,
      },
      {
        path: "prizes",
        element: <CustomerPrizes />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout allowedRoles={['Customer', 'Sales', 'Manager', 'Admin']}/>,
    children: [
      {
        path: "achievements",
        element: <CustomerTasks allowedRoles={['Customer', 'Sales']}/>,
      },
      {
        path: "prizes",
        element: <CustomerPrizes allowedRoles={['Customer', 'Sales']}/>,
      },
      {
        path: "profile",
        element: <UserProfile />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      
    ],
  },
  {
    path: 'email-verify',
    element: <AuthLayout children={<VerifyEmail />} />,
  },
  {
    path: "reset-password",
    element: <AuthLayout children={<ResetPassword />} />,
  },
  {
    path: "404",
    element: <NotFound />,
  },

];

export default routes;
