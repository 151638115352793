import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { withTheme } from "@emotion/react";
import { darken } from "polished";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";

import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  ListItemButton,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import NavbarMessagesDropdown from "./NavbarMessagesDropdown";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import NavbarUser from "./NavbarUser";

// import Typography
import Typography from "@mui/material/Typography";

import { ReactComponent as Logo } from "../../vendor/logo.svg";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: auto;
  height: 40px;
`;

const Navbar = ({ onDrawerToggle }) => {
  const { t } = useTranslation();
  
  return (
    <React.Fragment>
      <div style={{position: "absolute", left: 0, zIndex: 2200, width: "100%"}}>
        <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Brand component={NavLink} to="/">
                <BrandIcon />
              </Brand>
            </Grid>
            <Grid item xs />
            <Grid item>
              {/* <NavbarMessagesDropdown />
              <NavbarNotificationsDropdown />
              <NavbarLanguagesDropdown />
              <NavbarUserDropdown /> */}
              <NavbarUser />
            </Grid>
          </Grid>
        </Toolbar>
        </AppBar>
      </div>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
