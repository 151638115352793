import React, { useEffect, useState } from "react";
import {useLocation, NavLink, useNavigate} from "react-router-dom";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import axios from "../../utils/axios";
import {useDispatch} from "react-redux";

import {Paper, Typography, Button, Grid, MenuItem} from "@mui/material";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import * as Yup from "yup";
import YupPassword from 'yup-password';
import {sendNotification} from "../../redux/slices/notifications";
import {Formik} from "formik";


import {
	Alert as MuiAlert,
	TextField as MuiTextField,
	FormControl as MuiFormControl,
} from "@mui/material";

import { spacing } from "@mui/system";
const TextField = styled(MuiTextField)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const Alert = styled(MuiAlert)(spacing);

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: auto;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

YupPassword(Yup);

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ResetPassword() {
  const dispatch = useDispatch();
	let query = useQuery();
	const navigate = useNavigate();

	const [message, setMessage] = useState({
		status: false,
		text: "Loading...",
	});

	const params = {
    token: query.get("token"),
    email: query.get("email"),
	}

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title="Sign Up" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Password Reset
        </Typography>
        <Typography component="p" variant="body1" align="center">
            Please set your password.
        </Typography>

        <Formik
            initialValues={{
                password: '',
                confirm: '',
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string().max(255).required('Password is required')
                    .min(8,'Password must contain at least: 8 Characters, One Capital Letter and One Symbol.')
                    .minLowercase(1, 'Password must contain at least 1 lower case letter')
                    .minUppercase(1, 'Password must contain at least 1 upper case letter')
                    .minSymbols(1, 'Password must contain at least 1 special character'),
                confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirmaion is required'),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    const response = await axios.post(`/api/reset-password/?token=${params.token}&email=${params.email}`, values);
                    dispatch(sendNotification({message: "Password successfuly updated", type: "success"}));
                    navigate('/');
                } catch (error) {
                    const message = error.response.data.message || "Something went wrong, please try again";

                    dispatch(sendNotification({message: message, type: "error"}));
                    setStatus({ success: false });
                    setErrors({ submit: message });
                }
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                        <Alert mt={2} mb={3} severity="warning">
                            {errors.submit}
                        </Alert>
                    )}

                    <Paper elevation={2} sx={{padding: "20px"}}>
                        <TextField
                            fullWidth
                            label="Password"
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            my={2}
                            type={'password'}
                        />
                        <TextField
                            fullWidth
                            label="Confirm Password"
                            name="confirm"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.confirm}
                            variant="outlined"
                            error={Boolean(touched.confirm && errors.confirm)}
                            helperText={touched.confirm && errors.confirm}
                            my={2}
                            type={'password'}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={isSubmitting}
                            sx={{mt: 3}}
                        >
                            Reset
                        </Button>
                    </Paper>
                </form>
            )}
        </Formik>
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
