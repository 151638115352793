import React from "react";
import styled from "@emotion/styled";

import { Typography } from "@mui/material";

import SidebarNavList from "./SidebarNavList";


const SidebarNavSection = (props) => {
  const {
    pages,
    className,
    component: Component = "nav",
    ...rest
  } = props;

  return (
    <Component {...rest}>
      <SidebarNavList pages={pages} depth={0} />
    </Component>
  );
};

export default SidebarNavSection;
