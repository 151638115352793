import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import { setSession, getAccessToken } from "../utils/sanctum";

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.js` file.

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const SanctumReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(SanctumReducer, initialState);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const accessToken = getAccessToken();

      if (accessToken) {
        setSession(accessToken);

        const response = await axios.get("/api/user");
        const user = response.data;

        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const signIn = async (email, password) => { 
    const response = await axios.post("api/login", {
      email,
      password,
    });

    const { token } = response.data;

    setSession(token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user: response.data.user,
      },
    });
  };

  const signOut = () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { token, user } = response.data;

    window.localStorage.setItem("accessToken", token);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = async (email) => {
    const response = await axios.post("api/forgot", {
      email
    });

    return response.data;
  }

  const refresh = async () => {
    initialize();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "sanctum",
        signIn,
        signOut,
        signUp,
        resetPassword,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
