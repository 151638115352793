import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, allowedRoles=[] }) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  
  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  if((user?.role?.length > 0) && !allowedRoles.includes(user?.role)) {
    return <Navigate to="/404" />
  }

  const location = useLocation();
  if(location?.pathname === '/'){
    if((user?.role === 'Customer') || (user?.role === 'Sales')) {
      return <Navigate to="/achievements" />;
    }
    if(user?.role === 'Manager') {
      return <Navigate to="/company/achievements" />;
    }

    return <Navigate to="/dashboard/companies" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
