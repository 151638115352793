import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  prizesList: [],
	links: {},
	meta: {}
};


export const prizesSlice = createSlice({
  name: "prizesList",
  initialState,
  reducers: {
		setPrizes: (state, action) => {
			state.prizesList = action.payload.data;
			state.links = action.payload.links;
			state.meta = action.payload.meta;
		},
  },
});

export const { setPrizes } = prizesSlice.actions;

export function getPrizes(page, searchTerm) {
  page = page || 1;
  searchTerm = searchTerm || null;

  let endpoint = '/api/prizes?page=' + page;
  if(searchTerm) {
    endpoint = endpoint + '&search=' + searchTerm;
  }

  return async dispatch => {
    const response = await axios.get(endpoint);
    dispatch(prizesSlice.actions.setPrizes(response.data));
  };
}

export default prizesSlice.reducer;
