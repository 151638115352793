import React from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import {
  Tooltip,
  Menu,
  MenuItem,
  Typography,
  Grid,
  Avatar,
  IconButton as MuiIconButton,
} from "@mui/material";

const IconButton = styled(MuiIconButton)`
  padding: 0;
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const { user, signOut } = useAuth();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Grid item mr={2} display={{ xs: "none", sm: "block" }}>
          {!!user && user.role !== 'Admin' && (
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {user.first_name + " " + user.last_name} ({user.score} points)
            </Typography>
          )}

          {!!user && user.role === 'Admin' && (
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {user.first_name + " " + user.last_name}
              </Typography>
          )}
          {/* Demo data */}
        </Grid>
        <Grid item mr={3}>
          <Tooltip title="Account">
            <IconButton
              aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={toggleMenu}
              color="inherit"
              size="large"
            >
              {!!user && <Avatar alt={user.first_name + " " + user.last_name} src={user.avatar} />}
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorMenu}
            open={Boolean(anchorMenu)}
            onClose={closeMenu}
          >
            {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}
            <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
