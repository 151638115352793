import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  usersList: [],
	links: {},
	meta: {}
};


export const usersSlice = createSlice({
  name: "usersList",
  initialState,
  reducers: {
		setUsers: (state, action) => {
			state.usersList = action.payload.data;
			state.links = action.payload.links;
			state.meta = action.payload.meta;
		},
  },
});

export const { setUsers } = usersSlice.actions;

export function getUsers(page) {
  page = page || 1;

  return async dispatch => {
    const response = await axios.get('/api/users?page=' + page);
    dispatch(usersSlice.actions.setUsers(response.data));
  };
} 

export function searchUsers(query, page) {
  page = page || 1;
  
  return async dispatch => {
    const response = await axios.get('/api/users?search=' + query + '&page=' + page);
    dispatch(usersSlice.actions.setUsers(response.data));
  };
}

export default usersSlice.reducer;
