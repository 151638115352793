import axios from "axios";
import Cookies from "js-cookie";

// API Base URL
const baseURL = {
  development: "http://localhost/",
  staging: "https://cynet-channelx.wearekode.com/",
  production: "https://api-channelpartners.cynet.com/",
};

const axiosInstance = axios.create({
  baseURL: process.env.API_BASE_URL ?? baseURL.production,
  // baseURL: process.env.API_BASE_URL ?? 'http://localhost/',
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

const onRequest = (config) => {
  // If http method is in array and XSRF-TOKEN cookie is
  // not present, call '/sanctum/csrf-cookie' to set CSRF token, then
  // proceed with the initial response
  if (
    ["post", "put", "delete"].includes(config.method) &&
    !Cookies.get("XSRF-TOKEN")
  ) {
    return setCSRFToken().then((response) => config);
  }
  return config;
};

const setCSRFToken = () => {
  return axiosInstance.get("sanctum/csrf-cookie", { withCredentials: false });
};

axiosInstance.interceptors.request.use(onRequest, null);

// axiosInstance.interceptors.response.use(
//   response => response,
//   error => {
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )
//   }
// );

export default axiosInstance;
