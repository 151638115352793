import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { en } from "faker/lib/locales";
import { endOfDay } from "date-fns";

const initialState = {
  submissionsList: [
  ],
	links: {},
	meta: {}
};


export const submissionsSlice = createSlice({
  name: "submissionsList",
  initialState,
  reducers: {
		setSubmissions: (state, action) => {
			state.submissionsList = action.payload.data;
			state.links = action.payload.links;
			state.meta = action.payload.meta;
		},
  },
});

export const { setSubmissions } = submissionsSlice.actions;

export function getSubmissions(page) {
  page = page || 1;
  let endpoint = 'api/user-tasks?page=' + page; 

  return async dispatch => {
    const response = await axios.get(endpoint);
    dispatch(submissionsSlice.actions.setSubmissions(response.data));
  };
}

export default submissionsSlice.reducer;
